<template>
    <div class="login-box">
      <div class="stars"></div>
      <img class="login-img" src="../../assets/bj3.jpg" alt="" />
      <div class="login-input">
        <div style="text-align: center; height: 100%">
          <h3 class="h3">DataTalks数据解决方案</h3>
          <el-form :model="userLogin" :rules="userLoginRules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
            <div class="iten">
              <el-input  placeholder="请输入账号" prefix-icon="el-icon-user" v-model="userLogin.userName" @keyup.enter.native="submitForm('ruleForm')"> </el-input>
              <!-- <el-form-item label="用户名" prop="userName">
                <el-input v-model="userLogin.userName" placeholder="请输入账号" @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item> -->
            </div>
            <div class="iten">
              <el-input  placeholder="请输入密码" type="password" prefix-icon="el-icon-lock" v-model="userLogin.userPassword" @keyup.enter.native="submitForm('ruleForm')"> </el-input>
              <!-- <el-form-item label="密码" prop="userPassword">
                <el-input type="password" v-model="userLogin.userPassword" placeholder="请输入密码" @keyup.enter.native="submitForm('ruleForm')"></el-input>
              </el-form-item> -->
            </div>
            <div class="iten btn-item">
              <el-button class="btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  <script>
  // login page  https://cloud.axureshop.com/D5YW6I/#g=1&p=%E7%99%BB%E9%99%86%E9%A1%B5%E9%9D%A211
  
  import $ from 'jquery'
  export default {
    data() {
      return {
        userLogin: {
          userName: '',
          userPassword: ''
        },
        userLoginRules: {
          userName: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                // if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/.test(value)) {
                if (value) {
                  callback()
                } else {
                  callback(new Error('密码位6-10字母和数字的组合'))
                }
              },
              trigger: 'blur'
            }
          ],
          userPassword: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        }
      }
    },
    mounted() {
      setTimeout(() => {
        this.stars()
      }, 3000)
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          //验证通过，获取发送请求返回后台给的token,保存在sessage里
          if (valid) {
            if (this.userLogin.userName == 'xiaofan' && this.userLogin.userPassword == 'xiaofan') {
              this.router.push({
                path: '/index'
              })
            } else {
              this.$message.error('账号或密码错误！')
            }
          } else {
            return false
          }
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields()
      },
      stars() {
        var stars = 800 /*星星的密集程度，数字越大越多*/
        var $stars = $('.stars')
        var r = 800 /*星星的看起来的距离,值越大越远,可自行调制到自己满意的样子*/
        for (var i = 0; i < stars; i++) {
          var $star = $('<div/>').addClass('star')
          $stars.append($star)
        }
        $('.star').each(function () {
          var cur = $(this)
          var s = 0.2 + Math.random() * 1
          var curR = r + Math.random() * 300
          cur.css({
            transformOrigin: '0 0 ' + curR + 'px',
            transform:
              ' translate3d(0,0,-' + curR + 'px) rotateY(' + Math.random() * 360 + 'deg) rotateX(' + Math.random() * -50 + 'deg) scale(' + s + ',' + s + ')'
          })
        })
      }
    }
  }
  </script>
  
  <style>
  body {
    /* background: radial-gradient(200% 100% at bottom center, #f7f7b6, #e96f92, #75517d, #1b2947);
    background: radial-gradient(220% 105% at top center, #1b2947 10%, #75517d 40%, #e96f92 65%, #f7f7b6); */
    background-attachment: fixed;
    overflow: hidden;
  }
  
  @keyframes rotate {
    0% {
      transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
    }
    100% {
      transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(-360deg);
    }
  }
  .stars {
    transform: perspective(500px);
    transform-style: preserve-3d;
    position: absolute;
    bottom: 0;
    perspective-origin: 50% 100%;
    left: 50%;
    animation: rotate 90s infinite linear;
  }
  
  .star {
    width: 2px;
    height: 2px;
    background: #f7f7b6;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0 -300px;
    transform: translate3d(0, 0, -300px);
    backface-visibility: hidden;
  }
  .table {
    width: 400px;
    height: 350px;
    margin: 80px auto;
  }
  .table form {
    width: 100%;
  }
  .table .name {
    width: 280px;
    margin: 20px auto 30px auto;
    display: block;
    height: 30px;
    border-radius: 20px;
    border: none;
    background: rgba(0, 0, 0, 0.2);
    text-indent: 0.5em;
  }

  /* .table .btn {
    width: 100px;
    height: 30px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: none;
    color: white;
    margin: 0 auto;
    display: block;
  }
   */
  .login-box,
  .login-img {
    width: 100%;
    height: 100%;
  }
  .login-img {
    object-fit: cover;
  }
  .login-input {
    width: 360px;
    height: 300px;
    position: absolute;
    top: 27%;
    left: 50%;
    margin-left: -150px;
    padding: 2% 0%;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0px;
  }
  .h3 {
    color: #fff;
    font-size: 25px;
  }
  .iten {
    margin: 6% 8%;
    text-align: left;
  }


  .btn {
    height: 40px;
    width: 100%;
    font-size: 16px;
    margin-top:12%;
    /* border-style: solid; */
    border-color: white;
    background: rgba(0, 0, 0, 0.03);
    /* background: #409EFF; */
    border-radius: 2px;
    /* border: 1px; */
    color: white;
    /* margin: 0 auto; */
    display: block;
  }

/*鼠标悬浮，没有按下；鼠标按下后抬起，没有移开*/
.btn:focus, .btn:hover{
    background: #eaf5ff;
    border: 1px solid #2794f8 !important;
    color: #2794f8;
}
/*鼠标按下，没有抬起*/
.btn:active {
    background: #2794f8;
    color: white;
}





  .el-form-item__label {
    color: #fff;
    text-align: left;
  }
  
  /* .el-form demo-ruleForm {
    color: #fff;
  } */
  
  .el-input--small .el-input__inner{
    height:40px;
    line-height:40px;
  }
  </style>